@import '../../../assets/scss/_master.scss';

.setup-password {
  @include absolute(bottom 0 top 0 left 0 right 0);
  background-image: url('../../../assets/images/bkg-elev.jpg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  background-size: cover;

  .top-bar {
    height: 50px;
    width: 100%;
    background-color: rgba($color1, 0.9);
    position: relative;

    .login {
      width: 120px;
      height: 50px;
      line-height: 50px;
      float: right;
      text-align: center;
      background-color: $color4;
      color: $color8;
    }

    .logo {
      background-image: url('../../../assets/svg/ml-logo.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
      @include align-vertically();
      left: 20px;
      height: 30px;
      width: 30px;
    }
  }

  .content {
    height: 100%;
    width: 100%;
    background-color: rgba($color1, 0.9);

    .form-container {
      @include align-both-directions();
      text-align: center;

      .title {
        font-size: $headline;
        color: $color4;
      }

      .sub-title {
        font-size: $content1;
        color: $color8;
        margin: 10px auto;
        line-height: 1.45em;

        &.help {
          font-size: $content2;
        }

        &.error {
          color: $errorText;
        }
      }

      input {
        width: 300px;
        height: 45px;
        @include box-sizing(border-box);
        padding: 15px;
        border-radius: 10px;
        background-color: $color8;
        margin: 20px auto;
        display: block;
      }

      .btn {
        background-color: $color6;
        color: $color8;
        text-align: center;
        margin: auto;
        padding: 15px;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        &.login {
          margin-top: 35px;
        }

        &.busy {
          pointer-events: none;
          opacity: 0.5;
        }

        .icon {
          width: 25px;
          height: 25px;
          margin-right: 10px;
        }
      }

      a {
        text-decoration: none;
        color: $pacificBlue;
      }
    }
  }
}
