import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard  {
    constructor(private authService: AuthService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.authService.isLoggedIn() && !this.authService.isAuthExpired()) {
            return true;
        } else {
            // Store the attempted URL for redirecting
            this.authService.redirectUrl = state.url;

            this.authService.logout();
            return false;
        }
    }
}
