import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpResponse, HttpEvent, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';

import { ArgoGateway } from '../shared/argo.gateway';
import { AuthService } from './auth.service';
import { Lookup } from '../shared/lookup';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private argoGateway: ArgoGateway, private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // if skipping auth also clear out the SkipAuthHeader itself
    if (req.headers.has(Lookup.Auth.SkipAuthHeader))
      return next.handle(req.clone({ headers: new HttpHeaders() }));

    req = this.ensureFullDomain(req);
    req = this.addAuthHeaders(req);

    return next.handle(req).pipe(
      tap(
        event => {
          this.readResponseHeaders(event);
        },
        (error: HttpErrorResponse) => {
          if (error.status === 401) this.authService.logout();
        }
      )
    );
  }

  private addAuthHeaders(req: HttpRequest<any>): HttpRequest<any> {
    const headersWithAuth = this.argoGateway.getHeadersByCurrentUser(req);
    return req.clone({ headers: headersWithAuth });
  }

  private ensureFullDomain(req: HttpRequest<any>): HttpRequest<any> {
    if (req.url.startsWith('/api'))
      return req.clone({ url: this.argoGateway.fullBaseDomain(req.url) });
    else return req;
  }

  private readResponseHeaders(event: HttpEvent<any>) {
    if (event instanceof HttpResponse) this.argoGateway.pullDataFromResponseHeaders(event.headers);
  }
}
