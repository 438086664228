import { Component } from '@angular/core';
import { trigger, state, style, animate, transition, keyframes } from '@angular/animations';
import { FormControl } from '@angular/forms';
import { BannerService, BannerMessage } from './banner.service';
import { AuthService } from 'app/auth/auth.service';

@Component({
    selector: 'permanent-banner',
    template: /*html*/`
                <div class="banner permanent" [ngClass]="{error: message.IsError}" [@fadeInOut]="'in'"
                *ngIf="message.Text">
                    {{message.Text }}
                    &nbsp;&nbsp;<span class="link" *ngIf="showLogout" (click)="logoutUser()">Logout</span>
                </div>`,
    // styles in default.scss
    animations: [
        trigger('fadeInOut', [
            state('in', style({ opacity: 1 })),
            state('void', style({ opacity: 0 })),
            transition('void <=> in', animate('200ms linear'))
        ])
    ]
})
export class PermanentBannerComponent {
    message: BannerMessage;
    showLogout: boolean;

    constructor(
        private bannerService: BannerService,
        private authService: AuthService
    ) {
        // subscribe to banner service
        this.bannerService.permanentMessage
            .subscribe(bMess => {
                this.message = bMess;
                this.showLogout = this.authService.isImpersonating();
            });
    }

    logoutUser() {
        this.authService.logout();
    }
}
