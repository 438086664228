import { Component } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { BannerService, BannerMessage } from './banner.service';

@Component({
  selector: 'banner',
  template: `<div
    class="banner"
    [ngClass]="{ error: message.IsError }"
    [@fadeInOut]="'in'"
    *ngIf="message.Text"
    (click)="hideNow()"
  >
    {{ message.Text }}
  </div>`,
  // styles in default.scss
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 1 })),
      state('void', style({ opacity: 0 })),
      transition('void <=> in', animate('200ms linear'))
    ])
  ]
})
export class BannerComponent {
  message: BannerMessage;
  private messageTimeout: number;

  constructor(private bannerService: BannerService) {
    // subscribe to banner service
    this.bannerService.message.subscribe(bMess => {
      window.clearTimeout(this.messageTimeout);

      this.message = bMess;
      this.messageTimeout = window.setTimeout(
        () => (this.message.Text = ''),
        this.message.TimeoutExpire
      );
    });
  }

  hideNow() {
    this.message.Text = '';
    window.clearTimeout(this.messageTimeout);
  }
}
