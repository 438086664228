@import '../../../assets/scss/_master.scss';

notification-bell {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .notification-icon {
    @include flex-center-center();
    position: relative;

    svg-helper {
      width: 20px;
    }

    .notification-badge {
      position: absolute;
      top: -5px;
      right: -7px;
      padding: 3px 6px;
      border-radius: 50%;
      background: $errorText;
      color: $color8;
      font-size: 11px;
    }
  }

  .notification-popover {
    width: 33%;
    position: absolute;
    z-index: 3;
    margin-top: -28px;
    max-width: 550px;
    box-shadow: 0 4px 7px $color3;
    border-radius: 4px;
    top: 75px;
    background-color: $color8;

    &::before {
      height: 0;
      width: 0;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-bottom: 15px solid $color1;
      content: '';
      @include align-horizontally();
      top: -11px;
    }

    .notification-popover-content {
      max-height: 80vh;
      overflow: auto;
    }

    mat-spinner {
      margin: 20px auto;
    }

    .notification-title {
      background-color: $color1;
      color: $color8;
      font-size: $content1;
      padding: 15px;
      @include border-top-radius(4px);
      @include flex-center-center();
    }

    .notification-item {
      margin: 0 1rem;
      padding: 1rem 0;
      border-bottom: 1px solid $color3;
      white-space: normal;

      &:last-of-type {
        border-bottom: none;
      }
    }

    .no-notifications {
      padding: 1rem;
      text-align: center;
    }
  }
}
