import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';

import { SearchModule } from '../search/search.module';
import { ApolloCommonModule } from '../shared/apollo.common.module';
import { BannerComponent } from '../shared/banner.component';
import { BannerService } from '../shared/banner.service';
import { PermanentBannerComponent } from '../shared/permanent-banner.component';
import { BaseComponent } from './base.component';
import { BaseRoutingModule } from './base.routing';
import { HomeComponent } from './home.component';
import { IconsComponent } from './icons/icons.component';
import { NavComponent } from './nav.component';
import { NotificationBellComponent } from './notification-bell/notification-bell.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    BaseRoutingModule,
    ApolloCommonModule,
    SearchModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatProgressSpinnerModule
  ],
  declarations: [
    BaseComponent,
    HomeComponent,
    NavComponent,
    BannerComponent,
    PermanentBannerComponent,
    IconsComponent,
    NotificationBellComponent
  ],
  providers: [
    BannerService,
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500 } }
  ]
})
export class BaseModule {}
