import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthService } from '../auth/auth.service';
import { UserService } from '../entities/user.service';
import { User } from '../entities/user';
import { Lookup } from '../shared/lookup';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  friendlyMessage = '';
  userEmail: string;
  userPassword: string;
  showForgot = false;
  requestingAction = false;
  isMLEmployee = false;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService
  ) {}

  ngOnInit() {
    const querystring = this.route.snapshot.queryParamMap.get('mainsite');
    if (!querystring) return;

    const qsJson = JSON.parse(querystring.replace(/^'|'$/g, ''));
    const username = qsJson['UserName'];
    const token = qsJson['headers'] ? qsJson['headers']['x-ml-authtoken'] : '';
    if (username && token) {
      this.requestingAction = true;
      localStorage.setItem(Lookup.Auth.HmacTokenKey, token);
      localStorage.setItem(Lookup.Auth.CurrentUserKey, username);

      this.userService.getByUsername(username).subscribe(
        (user: User) => {
          this.authService.setUserCredentialsFromAPI(user);
          this.router.navigate(['/home']);
        },
        error => {
          if (error.status === 404) {
            this.friendlyMessage =
              'Sorry, we didn\'t recognize that email and password combination.';
          } else {
            this.friendlyMessage =
              'Sorry, an error occurred while trying to log in. Please try again.';
          }
          this.requestingAction = false;
        }
      );
    }
  }

  login() {
    if (this.userEmail && this.userPassword) {
      this.requestingAction = true;
      this.authService.login(this.userEmail, this.userPassword).subscribe(
        user => {
          if (this.authService.redirectUrl) {
            const url = this.authService.redirectUrl;
            this.authService.redirectUrl = '';
            this.router.navigateByUrl(url);
          } else {
            this.router.navigate(['/home']);
          }
        },
        error => {
          if (error.status === 404) {
            this.friendlyMessage =
              'Sorry, we didn\'t recognize that email and password combination.';
          } else {
            this.friendlyMessage =
              'Sorry, an error occurred while trying to log in. Please try again.';
          }
          this.requestingAction = false;
        }
      );
    } else {
      this.friendlyMessage = 'Please enter your email and password.';
    }
  }

  toggleForgotForm(doShow: boolean) {
    this.friendlyMessage = '';
    this.showForgot = doShow;
  }

  checkForMLEmployee(newVal) {
    this.isMLEmployee =
      (newVal &&
        (newVal.indexOf('@medialabinc.com') > -1 ||
          newVal.indexOf('@ml3ds.com') > -1 ||
          newVal.indexOf('@medialab3dstudio.com') > -1)) ||
      false;

    this.friendlyMessage =
      this.isMLEmployee && this.showForgot
        ? 'MediaLab user password cannot be reset via Converge. Please contact IT department for support.'
        : '';
  }

  forgotPassword() {
    if (this.isMLEmployee) {
      this.friendlyMessage =
        'MediaLab user password cannot be reset via Converge. Please contact IT department for support.';
    } else if (this.userEmail) {
      this.requestingAction = true;
      this.authService.sendPasswordEmail(this.userEmail).subscribe(
        res => {
          this.requestingAction = false;
          this.friendlyMessage = 'Email sent successfully!';
        },
        error => {
          this.requestingAction = false;
          this.friendlyMessage =
            'Sorry, an error occurred while trying to send the email. Please ensure the email address is correct.';
        }
      );
    }
  }
}
