﻿<div class="login-page">
    <div class="menu">
        <div class="title-container">
            <div class="title">Welcome to</div>
            <div class="product-title">Converge</div>
        </div>
        <div class="form-container">
            <form id="login" *ngIf="!showForgot" (ngSubmit)="login()" #loginForm="ngForm">
                <div class="message error" *ngIf="friendlyMessage">{{friendlyMessage}}</div>
                <input name="email" class="email" type="text" (ngModelChange)="checkForMLEmployee($event)" placeholder="Email" inputmode="email"
                       [(ngModel)]="userEmail" autofocus />
                <input name="password" class="password" type="password" placeholder="Password"
                       [(ngModel)]="userPassword" />
                <button class="btn submit" type="submit" [ngClass]="{busy: requestingAction}">
                    <span *ngIf="!requestingAction">Login</span>
                    <div class="loading-bars" *ngIf="requestingAction">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </button>
                <div class="btn subtle" [class.hide]="isMLEmployee" (click)="toggleForgotForm(true)">Forgot Password?</div>
            </form>
            <form id="forgot-pw" *ngIf="showForgot" (ngSubmit)="forgotPassword()" #forgotForm="ngForm">
                <div class="message">Forgot your password? No problem! Enter your email and we will send instructions to reset it.</div>
                <div class="message info" *ngIf="friendlyMessage">{{friendlyMessage}}</div>
                <input name="email" class="email" type="text" placeholder="Email" inputmode="email"
                       [(ngModel)]="userEmail" (ngModelChange)="checkForMLEmployee($event)" />
                <button class="btn submit" [ngClass]="{busy: requestingAction || isMLEmployee}"
                        type="submit">
                    Submit
                </button>
                <div class="btn subtle" (click)="toggleForgotForm(false)">Back to login</div>
            </form>
        </div>
        <div class="logo-container">
            <div class="ml-logo"></div>
            <div class="label">MediaLab</div>
        </div>
    </div>
</div>