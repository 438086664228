﻿<div class="forgot-pw-page">
    <div class="top-bar">
        <div class="logo"></div>
        <div class="login btn" routerLink="/login">Login</div>
    </div>
    <div class="content">
        <div class="form-container" *ngIf="userName && token">            
            <div class="needs-reset" *ngIf="!successfullyChanged">
                <div class="title">Change Password</div>
                <div class="sub-title">Enter your new password and confirm it below.</div>
                <div class="sub-title help">Password must be at least 8 characters and contain at least 1 uppercase, 1 lowercase, and 1 special character or number.</div>                               
                <form id="resetForm" (ngSubmit)="changePassword()">
                    <input name="newPassword" type="password" placeholder="New Password" [(ngModel)]="newPassword" #newPw="ngModel" />
                    <input name="confirmPassword" type="password" placeholder="Confirm Password" [(ngModel)]="confirmPassword" #confirmPw="ngModel"
                           [ngClass]="{invalid: newPw.value !== confirmPw.value}"  />
                    <div class="sub-title help error">{{errorMessage}}</div>
                    <button class="btn submit" [ngClass]="{busy: requestingAction}" type="submit">Submit</button>
                </form>                                
            </div>
            <div class="successfully-reset" *ngIf="successfullyChanged">
                <div class="title">Password Changed Successfully</div>
                <div class="sub-title">You can return to the login page and use this new password by clicking below.</div>
                <div class="btn login" routerLink="/login">Login</div>
            </div>
        </div>
        <div class="form-container" *ngIf="!userName || !token">
            <div class="title">Whoops!</div>
            <div class="sub-title">An error occurred while preparing to reset your password. Please try requesting another reset email from the login page. If this problem persists then contact support.</div>
        </div>
    </div>
</div>