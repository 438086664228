icons {
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  height: 100%;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 0 0 130px;
    margin: 20px;

    svg-helper {
      width: 50px;
      height: 50px;
      margin-bottom: 10px;

      svg {
        width: 100%;
        height: 100%;

        * {
          fill: black;
        }
      }
    }
  }
}
