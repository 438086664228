import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { BaseComponent } from './base.component';
import { HomeComponent } from './home.component';
import { AuthGuard } from '../auth/auth.guard';
import { CurrentUserResolve } from './current-user.resolve';
import { SuperAdminGuard } from '../shared/super-admin.guard';
import { CanDeactivateComponentGuard } from '../shared/can-deactivate-component.guard';
import { PublicComponent } from '../public/public/public.component';
import { IconsComponent } from './icons/icons.component';

const baseRoutes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthGuard],
    resolve: {
      currentUser: CurrentUserResolve
    },
    children: [
      { path: 'home', component: HomeComponent },
      // helpful to list out all icons, not actual app page
      {
        path: 'icons',
        component: IconsComponent
      },
      {
        path: 'users',
        loadChildren: () => import('app/users/users.module').then(m => m.UsersModule)
      },
      {
        path: 'management-maps',
        loadChildren: () =>
          import('app/management-maps/management-maps.module').then(m => m.ManagementMapsModule)
      },
      {
        path: 'admin',
        loadChildren: () => import('app/admin/admin.module').then(m => m.AdminModule)
      },
      {
        path: 'orders',
        loadChildren: () => import('app/orders/orders.module').then(m => m.OrdersModule)
      },
      {
        path: 'tools',
        loadChildren: () => import('app/tools/tools.module').then(m => m.ToolsModule)
      },
      {
        path: 'help',
        loadChildren: () => import('app/help/help.module').then(m => m.HelpModule)
      },
      {
        path: '3d-configurator',
        loadChildren: () => import('app/projects/projects.module').then(m => m.ProjectsModule)
      },
      {
        path: 'web-spinner',
        loadChildren: () =>
          import('app/web-spinner/web-spinner.module').then(x => x.WebSpinnerModule)
      },
      {
        path: 'content-editor',
        loadChildren: () =>
          import('app/content-editor/content-editor.module').then(m => m.ContentEditorModule)
      },
      {
        path: 'ar-viewer',
        loadChildren: () => import('app/ar-viewer/ar-viewer.module').then(m => m.ArViewerModule)
      },
      {
        path: 'data-scrape',
        loadChildren: () =>
          import('app/data-scrape/data-scrape.module').then(m => m.DataScrapeModule)
      },
      {
        path: 'pano',
        loadChildren: () => import('app/pano/pano.module').then(m => m.PanoModule)
      },
      {
        path: '',
        loadChildren: () => import('app/forms/forms.module').then(m => m.ApolloFormsModule),
        canActivate: [SuperAdminGuard]
      }
    ]
  },
  {
    path: 'public',
    component: PublicComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(baseRoutes)],
  exports: [RouterModule],
  providers: [AuthGuard, CurrentUserResolve, SuperAdminGuard, CanDeactivateComponentGuard]
})
export class BaseRoutingModule {}
