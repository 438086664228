import { Component, HostListener, OnInit } from '@angular/core';
import { Router, RouterEvent, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

import { AuthService } from 'app/auth/auth.service';
import { ToastService } from './shared/toast/toast.service';

@Component({
  selector: 'apollo',
  template: `
    <router-outlet>
      <div class="initial-loader">
        <h4>Loading...</h4>
        <div class="loading-bars">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </router-outlet>
  `
})
export class AppComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private router: Router,
    private toaster: ToastService
  ) {}

  ngOnInit() {
    let asyncLoadCount = 0;
    this.router.events
      .pipe(
        filter(
          event => event instanceof RouteConfigLoadStart || event instanceof RouteConfigLoadEnd
        )
      )
      .subscribe(event => {
        if (event instanceof RouteConfigLoadStart) {
          asyncLoadCount++;
        } else if (event instanceof RouteConfigLoadEnd) {
          asyncLoadCount--;
        }

        if (!!asyncLoadCount) this.toaster.showLoading();
        else this.toaster.hideLoading();
      });
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHander() {
    if (this.authService.isImpersonating()) {
      this.authService.logout();
    }
  }
}
