import { throwError as observableThrowError, Observable } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

import { BannerService } from '../shared/banner.service';
import { AuthService } from '../auth/auth.service';
import { UserService } from '../entities/user.service';
import { User } from '../entities/user';

@Injectable()
export class CurrentUserResolve  {
    constructor(
        private authService: AuthService,
        private router: Router,
        private userService: UserService,
        private bannerService: BannerService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<User> | any {
        // if already logged in and current user in memory then return it
        if (this.authService.isLoggedIn() && this.authService.currentUser) {
            if (this.authService.isImpersonating()) {
                this.bannerService.showPermanentMessage(
                    'Warning! You are currently impersonating  as ' +
                        this.authService.currentUser.UserName,
                    false
                );
            } else {
                this.bannerService.reset();
            }
            return this.authService.currentUser;
        }

        // attempt to load user based on cached username
        return this.authService.loadCurrentUser().pipe(
            map((user: User) => {
                if (this.authService.isImpersonating()) {
                    this.bannerService.showPermanentMessage(
                        'Warning! You are currently impersonating  as ' +
                            this.authService.currentUser.UserName,
                        false
                    );
                } else {
                    this.bannerService.reset();
                }
                return user;
            }),
            catchError(error => {
                this.authService.logout();
                return observableThrowError(
                    'Could not load the current user data. Most likely the session timed out and re-authentication is needed.'
                );
            })
        );
    }
}
