import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { getAllLibraryIconNames } from '@ml/web-components';
import { LegacyIcons } from '../../shared/svg-helper/legacy-icons';

@Component({
  selector: 'icons',
  templateUrl: './icons.component.html',
  styleUrls: ['./icons.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconsComponent implements OnInit {
  iconNames = [...getAllLibraryIconNames(), ...Object.keys(LegacyIcons)].sort();

  constructor() {}

  ngOnInit(): void {}
}
