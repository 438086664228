import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PublicComponent } from './public.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [PublicComponent],
  imports: [CommonModule, RouterModule],
  exports: [],
  providers: []
})
export class PublicModule {}
