import { NgModule }      from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule }   from '@angular/forms';

import { LoginRoutingModule } from './login.routing';
import { LoginComponent } from './login.component';
import { ForgotComponent } from './forgot.component';
import { SetupPasswordComponent } from './setup-password/setup-password.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        LoginRoutingModule
    ],
    declarations: [
        LoginComponent,
        ForgotComponent,
        SetupPasswordComponent
    ],
    providers: []
})
export class LoginModule { }
