﻿@import '../../assets/scss/_master.scss';

$topHeight: 50px;
$topHeightLarge: 170px;
$sidePadding: 43px;
$upDownPadding: 30px;
$gutterWidth: 300px;

$labelFontSize: $content1;
$labelBottomMargin: 10px;
$inputFontSize: $content2;
$inputMarginRight: 30px;
$inputMarginBottom: 35px;
$inputHeight: 32px;
$inputWidth: 200px;
$inputWidthLg: 500px;
$inputWidthMed: 150px;
$inputWidthSm: 75px;
$inputWidthExSm: 70px;
$inputPadding: 8px;
$inputBorderRadius: 4px;
$inputBorderStyle: 1px solid $color12;

.main-section {
  @include absolute(top 0 bottom 0 right 0 left $navBarExpandedWidth);
  background-color: $color5;
  @include transition(left 300ms ease-out);
  z-index: 2;

  &.expanded {
    left: $navBarCollapsedWidth;
  }

  .feature-content {
    @include absolute(top 0 bottom 0 left 0 right 0);
  }

  .top {
    width: 100%;
    height: $topHeight;
    background-color: $color8;
    position: relative;
    padding: 0 $sidePadding;
    @include box-sizing(border-box);
    border-bottom: 2px solid $rowcolor4;

    &.large {
      height: $topHeightLarge;
    }

    .title-area {
      color: $color1;
      white-space: nowrap;
      height: 100%;
      display: inline-block;

      .back.btn {
        @include box-sizing(border-box);
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
        padding: 5px;
        width: 30px;
        height: 30px;
        @include transform(rotate(180deg));

        svg {
          path,
          polygon,
          circle {
            fill: $color1;
          }
        }
      }

      .text {
        display: inline-block;
        height: 100%;
        vertical-align: middle;

        .icon {
          width: 25px;
          height: 25px;
          display: inline-block;
          vertical-align: middle;
          margin-right: 15px;

          svg {
            path,
            polygon,
            circle,
            rect {
              fill: $color4;
            }
          }

          &.alt {
            svg {
              path,
              polygon {
                fill: $color3;
              }
            }
          }
        }

        .title {
          display: inline-block;
          vertical-align: middle;
          font-size: $headline;
          font-weight: bold;
          color: $color1;
          max-width: 700px;
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: $topHeight;
          margin: 0;

          span {
            color: $color3;
            border-right: 2px solid $color3;
            padding-right: 15px;
            margin-right: 5px;

            &:last-child {
              border-right: none;
            }

            &.name {
              color: $color1;
            }
          }
        }

        .sub-title {
          font-size: $content1;
          display: inline-block;
          margin-left: 25px;
          vertical-align: middle;

          .icon svg path {
            fill: $color3;
          }

          .item {
            padding-right: 10px;
            margin-right: 8px;
            display: inline-block;
            color: $color3;

            &:last-child:after {
              display: none;
            }
          }
        }
      }
    }

    .save.btn {
      @include absolute(top 0 right $sidePadding bottom 0);
      padding: 10px 35px;
      color: $color8;
      background-color: $color6;
      font-weight: bold;
      margin: auto auto;
      height: 20px;
      @include transition(opacity 200ms ease-in);

      &.busy {
        opacity: 0.8;
        pointer-events: none;
      }
    }

    .action.btn {
      @include absolute(top 0 right $sidePadding + 110 bottom 0);
      padding: 10px;
      color: $color8;
      background-color: $color7;
      display: inline-block;
      margin: auto 0 auto 0;
      height: 20px;
    }
  }

  .bottom,
  .pane,
  .section,
  .container {
    .title {
      margin-bottom: $labelBottomMargin;
      font-weight: 600;
      display: block;
      font-size: $labelFontSize;

      &.readonly {
        color: $color3;
      }
    }

    .input-row {
      white-space: nowrap;
      margin-bottom: $inputMarginBottom;

      .label,
      label {
        margin-bottom: $labelBottomMargin;
        font-weight: 600;
        display: block;
        font-size: $labelFontSize;

        &.readonly {
          color: $color3;
        }
      }
    }

    .color-picker-section {
      display: flex;
      margin-top: 5px;

      .color-toggle {
        display: inline-block;
        height: 20px;
        width: 20px;
        cursor: pointer;
        align-self: center;

        svg {
          path,
          polygon {
            fill: $color1;
          }
        }
      }

      .current-color {
        display: inline-block;
        height: 23px;
        width: 20px;
        border: 1px solid $color3;
        border-radius: 3px;
        margin: 0 10px 0 9px;
        align-self: center;
        flex-shrink: 0;
      }
    }
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
    select,
    .mat-mdc-select-trigger {
      @include box-sizing(border-box);
      padding: 0 $inputPadding;
      border: $inputBorderStyle;
      border-radius: $inputBorderRadius;
      outline: none;
      font-size: $inputFontSize;
      height: $inputHeight;
      width: $inputWidth;
      background-color: $color8;

      &:focus {
        border-color: $color3;
      }

      &.lg {
        width: $inputWidthLg;
      }

      &.full {
        width: 100%;
      }

      &.sm {
        width: $inputWidthSm;
      }

      &.ex-sm {
        width: $inputWidthExSm;
      }
    }

    input,
    textarea,
    .select {
      @include box-sizing(border-box);
      padding: $inputPadding;
      border: $inputBorderStyle;
      border-radius: $inputBorderRadius;
      outline: none;
      font-size: $inputFontSize;
      height: $inputHeight;
      width: $inputWidth;

      &:focus {
        border-color: $color3;
      }

      &.lg {
        width: $inputWidthLg;
      }

      &.med {
        width: $inputWidthMed;
      }

      &.sm {
        width: $inputWidthSm;
      }

      &.ex-sm {
        width: $inputWidthExSm;
      }

      &.readonly {
        pointer-events: none;
        opacity: 0.6;
      }

      &.password {
        opacity: 0;
        pointer-events: none;
        @include transition(opacity 200ms ease-out);

        &.show {
          opacity: 1;
          pointer-events: auto;
        }

        &.invalid {
          border: 2px solid $errorText;
        }
      }
    }

    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
      width: $inputWidthSm;
    }

    textarea {
      width: 460px;
      height: 116px;
      white-space: pre-wrap;
    }

    .readonly {
      pointer-events: none;
      opacity: 0.6;
    }

    .dropzone {
      width: 100%;
      border: 3px dashed $color3;
      border-radius: 10px;
      text-align: center;
      padding: 40px 25px;
      @include box-sizing(border-box);
      @include transition(all 200ms ease-in);
      @include user-select(none);

      &:hover {
        background-color: rgba($color1, 0.2);
      }

      &.drag-hover {
        background-color: rgba($color1, 0.5);

        .message {
          color: $rowcolor2;

          svg {
            path,
            rect,
            polygon {
              fill: $rowcolor2;
            }
          }
        }
      }

      .message {
        font-size: $content2;
        pointer-events: none;
        @include transition(color 200ms ease-in);

        svg {
          width: 100px;
          height: 75px;
        }
      }
    }
  }

  .bottom {
    padding: $upDownPadding $sidePadding;
    @include box-sizing(border-box);
    @include absolute(top $topHeight bottom 0 left 0 right 0);
    overflow-y: auto;
    overflow-x: hidden;

    &.with-gutter {
      padding-right: $gutterWidth;
    }

    .tab-container {
      &.primary-only {
        .tab {
          opacity: 0.2;
          pointer-events: none;

          &.primary {
            opacity: 1;
            pointer-events: auto;
          }
        }
      }

      .tab {
        display: inline-block;
        color: $color1;
        font-size: $content1;
        padding: 5px 0;
        margin-right: 100px;
        @include box-sizing(border-box);
        border-width: 0;
        @include transition(border-width 200ms ease-in);

        &:hover {
          border-bottom: 5px solid $color1;
          font-weight: bold;
        }

        &.active {
          border-bottom: 5px solid $color4;
          font-weight: bold;
        }

        span,
        svg {
          display: inline-block;
          vertical-align: middle;
        }

        svg {
          margin-right: 10px;
          width: 25px;
          height: 20px;
        }
      }
    }

    .tabbed-content {
      position: relative;
      padding: 30px 0;
      display: none;
      @include animation(fadeIn 175ms ease-in);

      &.active {
        display: block;
      }
    }

    &.small {
      top: $topHeightLarge;
    }

    .gutter {
      @include absolute(top $upDownPadding bottom $upDownPadding right 0);
      width: $gutterWidth;
      border-left: 1px solid $color12;
      padding: 20px;
      @include box-sizing(border-box);

      .meta-data {
        border-top: none;
        padding: 0;

        .items-container {
          display: block;
          margin-right: 0;
          margin-bottom: 35px;
        }
      }
    }
  }

  .disabled {
    & > .dropzone {
      pointer-events: none;
      opacity: 0.25;
    }

    .message {
      color: $color3;

      svg {
        fill: $color3;
      }
    }
  }

  .btn.disabled {
    pointer-events: none;
  }

  .tooltip {
    position: relative;

    &:hover {
      cursor: help;
      position: relative;
    }

    .icon {
      svg path {
        fill: $color4 !important;
      }
    }

    .tooltip-modal {
      display: none;
      @include align-vertically();
      left: 40px;
      background-color: $color8;
      padding: 15px;
      z-index: 4;
      min-width: 300px;
      text-decoration: none;
      color: $color1;
      border: 2px solid $color4;
      border-radius: 4px;
      font-size: $content2;
    }

    &:hover .tooltip-modal {
      display: block;

      &::before,
      &::after {
        @include absolute(top 50% right 100%);
        width: 0;
        height: 0;
        border: solid transparent;
        content: '';
      }

      &:before {
        border-right-color: $color4;
        border-width: 13px;
        margin-top: -13px;
      }

      &::after {
        border-right-color: $color8;
        border-width: 10px;
        margin-top: -10px;
      }

      .header {
        font-weight: bold;
      }

      .def {
        white-space: normal;
        margin-bottom: 13px;
        font-weight: normal;

        &:last-child {
          margin: 0;
        }
      }
    }
  }
}
