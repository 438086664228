﻿<nav class="nav-bar" [ngClass]="{collapsed: isCollapsed}">
  <div class="logo-container">
    <div class="icon">
      <svg-helper svgName="medialab_logo_color"></svg-helper>
    </div>
  </div>
  <div id="homeNav" class="item border-bottom" (click)="expandBar()" routerLink="/home" routerLinkActive="active"
       [routerLinkActiveOptions]="{ exact: true }" matTooltip="Home" matTooltipPosition="after" matTooltipClass="left-arrow"
       [matTooltipDisabled]="!isCollapsed">
    <div class="icon">
      <svg-helper svgName="user_home"></svg-helper>
    </div>
    <div class="label">Home</div>
  </div>
  <div class="loading-bars" *ngIf="!navItems && !isInError">
    <div></div>
    <div></div>
    <div></div>
  </div>
  <div class="modules-container">
    <div *ngFor="let navItem of navItems" class="module" [class.expanded]="expandedNavId === navItem.id">
      <button [id]="navItem.id" class="item" [class.active]="navItem.isRouteMatch(activeFullRoute)" [matTooltip]="navItem.title"
              matTooltipPosition="after" matTooltipClass="left-arrow" [matTooltipDisabled]="!isCollapsed && !navItem.needsTooltip" (click)="navAction(navItem)">
        <div class="icon">
          <svg-helper [svgName]="navItem.iconName"></svg-helper>
        </div>
        <div class="label">{{navItem.title}}</div>
      </button>
      <div class="sub-items">
        <button *ngFor="let subItem of navItem.subItems" class="item" [class.active]="subItem.isRouteMatch(activeFullRoute)"
                routerLink="{{subItem.route}}" [matTooltip]="subItem.disabled ? timeDisabledTooltip : subItem.title" [disabled]="subItem.disabled"
                matTooltipPosition="after" matTooltipClass="left-arrow" [matTooltipDisabled]="!subItem.needsTooltip">
          <div class="label">{{subItem.title}}</div>
        </button>
      </div>
    </div>
  </div>
  <div class="btns-container fixed">
    <div id="helpBtn" class="item border-bottom" (click)="expandBar()" routerLink="/help" routerLinkActive="active"
         [routerLinkActiveOptions]="{ exact: true }" matTooltip="Help" matTooltipPosition="after" matTooltipClass="left-arrow"
         [matTooltipDisabled]="!isCollapsed" [matTooltipDisabled]="!isCollapsed">
      <div class="icon">
        <svg-helper svgName="help"></svg-helper>
      </div>
      <div class="label">Help</div>
    </div>
    <div class="item border-bottom" (click)="doOpenSearch()" matTooltip="Crtl + Shift + F"
         matTooltipPosition="after" matTooltipClass="left-arrow" [matTooltipDisabled]="!isCollapsed">
      <div class="icon">
        <svg-helper svgName="mag_glass"></svg-helper>
      </div>
      <div class="label">Search</div>
    </div>
    <div id="logOutBtn" class="item border-bottom" (click)="logout()" matTooltip="Log Out" matTooltipPosition="after"
         matTooltipClass="left-arrow" [matTooltipDisabled]="!isCollapsed">
      <div class="icon">
        <svg-helper svgName="logout"></svg-helper>
      </div>
      <div class="label">Log Out</div>
    </div>
    <div class="item carrot" [class.left]="!isCollapsed" (click)="toggleSize()" matTooltip="Open / Collapse"
         matTooltipPosition="after" matTooltipClass="left-arrow" [matTooltipDisabled]="!isCollapsed">
      <div class="icon">
        <svg-helper svgName="carrot_arrow"></svg-helper>
      </div>
    </div>
  </div>
</nav>