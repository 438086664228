import { Component, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'base-layout',
    templateUrl: './base.component.html',
    styleUrls: ['./base.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class BaseComponent {
    constructor() {}
}
