import { NgModule } from '@angular/core';

import {
  AclService,
  ClientService,
  CommunityService,
  DivisionService,
  LotService,
  MetaDataService,
  NeighborhoodService,
  UserService,
  ManagementMapsService,
  FloorPlanService,
  MediaService,
  RootService,
  BuildingService,
  UnitService,
  ApiKeyService,
  ProductService,
  BuildingRootService,
  SettingsService,
  RpmService,
  IconService,
  HotspotGroupService,
  SpinnerService
} from '.';
import { PageService } from '../content-editor/services/page.service';
import { PublishService } from '../content-editor/services/publish.service';
import { BroadcastService } from './broadcast.service';

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [
    AclService,
    BroadcastService,
    ClientService,
    CommunityService,
    DivisionService,
    LotService,
    NeighborhoodService,
    UserService,
    ManagementMapsService,
    FloorPlanService,
    MediaService,
    RootService,
    SpinnerService,
    BuildingService,
    UnitService,
    ApiKeyService,
    ProductService,
    MetaDataService,
    BuildingRootService,
    SettingsService,
    RpmService,
    IconService,
    HotspotGroupService,
    PublishService,
    PageService
  ]
})
export class EntitiesModule {}
