import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Output,
  EventEmitter
} from '@angular/core';

import { AuthService } from '../../auth/auth.service';
import { RpmService, SettingsService } from '../../entities';
import { RpmJob } from '../../entities/rpm';

@Component({
  selector: 'notification-bell',
  templateUrl: './notification-bell.component.html',
  styleUrls: ['./notification-bell.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationBellComponent implements OnInit {
  @Output() hasJobsThatNeedAttention = new EventEmitter();
  jobStatusTrackerFeatureEnabled = false;
  jobsThatNeedAttention = new Array<RpmJob>();
  showNotificationPopover = false;
  viewedJobNotificationsSessionStorageKey = 'viewed-job-notifications';
  viewedNotificationsSessionStorageKey = 'viewed-notifications';
  unreadNotificationCount = 0;
  loading = true;
  notifications = new Array<INotification>();

  constructor(
    private authService: AuthService,
    private settingsService: SettingsService,
    private rpmService: RpmService,
    private cdr: ChangeDetectorRef
  ) {}

  async ngOnInit(): Promise<void> {
    const user = this.authService.currentUser;

    const shouldSeeCustomNotification = false; // user.EditorPermissions.Overall;
    const viewedNotificationIds = this.getViewedNotifications();
    this.notifications = shouldSeeCustomNotification
      ? CustomNotifications.filter(n => !viewedNotificationIds.includes(n.Id))
      : [];
    this.unreadNotificationCount = this.notifications.length;

    if (user.isSuperAdmin) {
      this.jobStatusTrackerFeatureEnabled = true;
    } else if (user.isDivisionAdmin || user.isStandardUser) {
      const divRole = user.Roles.find(r => r.ProjectId);
      this.jobStatusTrackerFeatureEnabled =
        await this.settingsService.isJobTrackerEnabledForDivision(divRole.ProjectId);
    } else if (user.ClientId) {
      this.jobStatusTrackerFeatureEnabled =
        await this.settingsService.isJobStatusTrackerEnabledForClient(user.ClientId);
    }

    if (!this.jobStatusTrackerFeatureEnabled) return;

    try {
      const jobIdsThatNeedAttention = await this.rpmService.getJobIdsThatNeedAttentionByUserId(
        user.Id
      );
      if (jobIdsThatNeedAttention?.length > 0) {
        this.hasJobsThatNeedAttention.emit();
        this.jobsThatNeedAttention = await this.rpmService.getJobsByIds(jobIdsThatNeedAttention);
        const viewedJobs = this.getViewedJobNotifications();
        this.unreadNotificationCount += jobIdsThatNeedAttention.filter(
          j => !viewedJobs.includes(j)
        ).length;
      }
      this.loading = false;
    } catch (e) {
      console.error(`Error getting jobs that need attention:`, e);
    }
    this.cdr.markForCheck();
  }

  toggleNotificationPopover() {
    this.showNotificationPopover = !this.showNotificationPopover;
    this.unreadNotificationCount = 0;

    if (this.showNotificationPopover) {
      this.setViewedJobNotifications(this.jobsThatNeedAttention.map(j => j.JobId));
    }
  }

  private getViewedJobNotifications(): number[] {
    const viewedJobNotificationsString = sessionStorage.getItem(
      this.viewedJobNotificationsSessionStorageKey
    );
    return viewedJobNotificationsString ? JSON.parse(viewedJobNotificationsString) : [];
  }

  private setViewedJobNotifications(jobIds: number[]) {
    sessionStorage.setItem(this.viewedJobNotificationsSessionStorageKey, JSON.stringify(jobIds));
  }

  private getViewedNotifications(): number[] {
    const viewedNotificationsString = sessionStorage.getItem(
      this.viewedNotificationsSessionStorageKey
    );
    return viewedNotificationsString ? JSON.parse(viewedNotificationsString) : [];
  }

  private setViewedNotifications(notificationIds: number[]) {
    sessionStorage.setItem(
      this.viewedNotificationsSessionStorageKey,
      JSON.stringify(notificationIds)
    );
  }

  private setNotificationAsViewed(notificationId: number) {
    const viewedNotificationIds = this.getViewedNotifications();
    if (!viewedNotificationIds.includes(notificationId)) viewedNotificationIds.push(notificationId);
    this.setViewedNotifications(viewedNotificationIds);
  }

  handleNotificationClick(notificationId: number) {
    this.setNotificationAsViewed(notificationId);
  }
}

export interface INotification {
  Id: number;
  Content: string;
}

const CustomNotifications: Array<INotification> = [];
