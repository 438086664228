import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { forkJoin as observableForkJoin } from 'rxjs';

import { AuthService } from '../auth/auth.service';
import { AclService, SettingsService, User } from '../entities';
import { CascadingDropdownsComponent } from '../shared/cascading-dropdowns/cascading-dropdowns.component';
import { Lookup } from '../shared/lookup';
import { IQuickLink, QuickLink } from '../shared/quick-link-editor/quick-link-editor.component';
import { ReportData } from '../tools/report/models/reportData';
import { ReportService } from '../tools/report/report.service';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {
  @ViewChild('embedContainer') embedContainer: ElementRef;
  @ViewChild(CascadingDropdownsComponent)
  cascadingDropdowns: CascadingDropdownsComponent;
  @ViewChild('rssSummary') rssSummary: ElementRef;
  user: User;
  marketingRssItem: RssItem;
  showSplashModules = false;
  showKioskLink = false;
  showKioskSettingEnabled = false;
  initInProgress = true;
  hasOrdersAccess = false;
  hasLinkGeneratorAccess = false;
  showJobStatusReport = false;
  disableEmbeddedRefresh = false;
  jobStatusReport: ReportData;
  quickLinks: QuickLink[] = [];
  hasJobsThatNeedAttention = false;

  private settingNameForJobStatusReport = 'Job Status';
  private displayJobStatusReportSettingTitle = 'DisplayJobStatusReport';
  private displaySplashModulesSettingTitle = 'DisplaySplashModules';
  private showKioskTitle = 'ShowKioskQuickLink';
  private topContent = `
    <h1>Hello!</h1>
    <br>
    <h2>{{userName}} has given {{amName}} a {{rating}} out of 5 star rating.</h2>
    `;
  private bottomContent = `
    <h3>Comments:</h3>
    <p>{{userComments}}</p>
    <h4>Submitted from Converge</h4>
    `;

  constructor(
    private authService: AuthService,
    private reportService: ReportService,
    private settingsService: SettingsService,
    private aclService: AclService,
    private http: HttpClient
  ) {}

  async ngOnInit() {
    const easternTzDate = new Date().toLocaleString('en-US', { timeZone: 'America/New_York' });
    const time = new Date(easternTzDate).getHours();

    this.user = this.authService.currentUser;
    if (!this.user.ClientName && this.user.isSuperAdmin) this.user.ClientName = 'MediaLab';

    if (this.user.isSuperAdmin) {
      this.showJobStatusReport = time > 7 && time < 20 ? true : false;
      this.showSplashModules = true;
      this.initModules();
      this.initInProgress = false;
    } else {
      // if non admin then check setting to show/hide splash modules
      this.settingsService
        .getClientSettingsByCategory(
          this.user.ClientId,
          Lookup.Settings.CategoryIds.HomeDashboard.General
        )
        .subscribe(settings => {
          const splashSetting = settings.find(
            s => s.Name === this.displaySplashModulesSettingTitle
          );
          const displayJobStatusReportSetting = settings.find(
            s => s.Name === this.displayJobStatusReportSettingTitle
          );
          const showKioskSetting = settings.find(s => s.Name === this.showKioskTitle);
          this.showKioskSettingEnabled = JSON.parse(showKioskSetting.Value);
          this.checkUserAccess();

          if (displayJobStatusReportSetting)
            this.showJobStatusReport =
              JSON.parse(displayJobStatusReportSetting.Value) && time > 7 && time < 20
                ? true
                : false;
          if (splashSetting) this.showSplashModules = JSON.parse(splashSetting.Value);

          if (this.showSplashModules) {
            this.initModules();
            this.initInProgress = false;
          } else {
            if (this.user.mappedDivisionIds.length > 0) {
              observableForkJoin(
                this.user.mappedDivisionIds.map(id =>
                  this.settingsService.getDivisionSettingsByCategory(
                    id,
                    Lookup.Settings.CategoryIds.HomeDashboard.General
                  )
                )
              ).subscribe(result => {
                const divSettings = result.reduce((all, curr) => all.concat(curr));
                const divSplashSettings = divSettings.filter(
                  divSetting => divSetting.Name === this.displaySplashModulesSettingTitle
                );
                const divisionsWithSplashEnabled = divSplashSettings
                  .filter(divSplashSetting => JSON.parse(divSplashSetting.Value) === true)
                  .map(setting => setting.ProjectId);
                this.showSplashModules = divisionsWithSplashEnabled.length > 0;

                if (this.showSplashModules) this.initModules(divisionsWithSplashEnabled);
                this.initInProgress = false;
              });
            }
            this.initInProgress = false;
          }
        });
      this.getQuickLinks();
    }
  }

  initModules(divIdsWithModulesEnabled: Array<number> = []) {
    let divisionId: number;

    if (divIdsWithModulesEnabled.length > 0) {
      divisionId = divIdsWithModulesEnabled[0];
    } else if (this.user.mappedDivisionIds.length > 0) {
      divisionId = this.user.mappedDivisionIds[0];
    }

    this.getMarketingRssItem();
    this.checkUserAccess();
    this.refreshEmbedded();
  }

  getQuickLinks() {
    if (this.user.isStandardUser && this.user.mappedCommunityIds.length > 0) {
      this.user.mappedCommunityIds.map(cid => {
        this.settingsService
          .getCommunitySettingsByCategory(cid, Lookup.Settings.CategoryIds.HomeDashboard.QuickLinks)
          .subscribe(comm => {
            const quickLinkComm = comm.find(x => x.Name === 'CustomLinks');
            if (quickLinkComm.Value) {
              const commValue: Array<IQuickLink> = JSON.parse(quickLinkComm.Value);
              commValue.forEach(val => this.quickLinks.push(new QuickLink(val)));
              this.quickLinks = this.quickLinks.reduce(this.removeDuplicateQuickLinks, []);
              this.checkUserAccess();
            }
          });
      });
    } else if (this.user.isDivisionAdmin && this.user.mappedDivisionIds.length > 0) {
      this.user.mappedDivisionIds.map(id => {
        this.settingsService
          .getDivisionSettingsByCategory(id, Lookup.Settings.CategoryIds.HomeDashboard.QuickLinks)
          .subscribe(div => {
            const quickLinkDiv = div.find(x => x.Name === 'CustomLinks');
            if (quickLinkDiv.Value) {
              const divValue: Array<IQuickLink> = JSON.parse(quickLinkDiv.Value);
              divValue.forEach(val => this.quickLinks.push(new QuickLink(val)));
              this.quickLinks = this.quickLinks.reduce(this.removeDuplicateQuickLinks, []);
              this.checkUserAccess();
            }
          });
      });
    } else {
      this.settingsService
        .getClientSettingsByCategory(
          this.user.ClientId,
          Lookup.Settings.CategoryIds.HomeDashboard.QuickLinks
        )
        .subscribe(settings => {
          const quickLinks = settings.find(x => x.Name === 'CustomLinks');
          if (quickLinks.Value) {
            const clientValue: Array<IQuickLink> = JSON.parse(quickLinks.Value);
            clientValue.forEach(val => this.quickLinks.push(new QuickLink(val)));
            this.checkUserAccess();
          }
        });
    }
  }

  private removeDuplicateQuickLinks(prev: QuickLink[], curr: QuickLink): QuickLink[] {
    const currentUrl = curr.urlType === 'internal' ? curr.route.Url : curr.url;
    const found = prev.find(
      (a: QuickLink) => (a.urlType === 'internal' ? a.route.Url : a.url) === currentUrl
    );
    if (found) return prev;
    else return [...prev, curr];
  }

  refreshEmbedded() {
    // Power BI Embedded has a 15 second limit between refresh requests
    this.disableEmbeddedRefresh = true;
    setTimeout(() => {
      this.disableEmbeddedRefresh = false;
    }, 15000);
  }

  private getMarketingRssItem() {
    this.http.get('/api/utility/rss/medialab3dsolutions.com/feed').subscribe((data: any) => {
      this.marketingRssItem = new RssItem();
      this.marketingRssItem.title = data.Title ? data.Title.Text : '';
      this.marketingRssItem.summary = data.Summary
        ? data.Summary.Text.replace(/&lt;/g, '<').replace(/&gt;/g, '>')
        : '';
      // usually there is an img src embedded, make sure its using https
      this.marketingRssItem.summary = this.marketingRssItem.summary.replace(/http:/g, 'https:');

      if (data.Links && data.Links[0]) this.marketingRssItem.link = data.Links[0].Uri;
    });
  }

  private checkUserAccess() {
    if (this.user.isSuperAdmin) {
      this.hasLinkGeneratorAccess = true;
      this.hasOrdersAccess = true;
      this.showKioskLink = this.showKioskSettingEnabled;
    } else {
      this.aclService.getModulesAuthorizedForUser(this.user).subscribe(acls => {
        if (acls.some(x => x.ResourceName === Lookup.Modules.Orders.Name))
          this.hasOrdersAccess = true;

        const toolsMod = Lookup.Modules.Tools;
        const toolsAcl = acls.find(x => x.ResourceName === toolsMod.Name);
        if (
          toolsAcl &&
          toolsMod.SpecialOperations.LinkGenerator.isUserAllowed(this.user, toolsAcl)
        ) {
          this.hasLinkGeneratorAccess = true;
        }

        this.quickLinks = this.quickLinks.filter(lnk => {
          if (lnk.urlType === 'external') {
            return lnk;
          } else {
            const quickMod = Lookup.Modules[lnk.route.Module];
            const modAcl = acls.find(x => x.ResourceName === quickMod.Name);
            if (modAcl) {
              if (quickMod.BaseUrl === lnk.route.Url) {
                return lnk;
              } else {
                const specialOps = quickMod.NavigableOperations;
                if (specialOps.some(op => op.isUserAllowed(this.user, modAcl))) return lnk;
              }
            }
          }
        });

        this.showKioskLink =
          this.showKioskSettingEnabled &&
          toolsMod.SpecialOperations.OfflineSCP.isUserAllowed(this.user, toolsAcl);
      });
    }
  }
}

class RssItem {
  title: string;
  summary: string;
  link: string;
}
