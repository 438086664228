import { NgModule } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { AuthService } from './auth/auth.service';
import { ArgoGateway } from './shared/argo.gateway';
import { BaseModule } from './base/base.module';
import { LoginModule } from './login/login.module';
import { CacheService } from './shared/cache.service';
import { AppStateService } from './shared/app-state.service';
import { EntitiesModule } from './entities/entities.module';
import { AuthInterceptor } from './auth/auth.interceptor';
import { PublicModule } from './public/public/public.module';
import { CRMAccountService } from './entities/crm-account.service';

@NgModule({ declarations: [AppComponent],
    bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        BaseModule,
        LoginModule,
        EntitiesModule,
        HammerModule,
        PublicModule], providers: [
        ArgoGateway,
        CacheService,
        AppStateService,
        CRMAccountService,
        AuthService,
        [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }],
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {}
