import { Location } from '@angular/common';
import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { AuthService } from '../auth/auth.service';
import { ACL, AclService, User } from '../entities';
import { SearchComponentTypes, SearchService } from '../search/search.service';
import { ApolloModuleModel } from '../shared/apollo-module-models';
import { Lookup } from '../shared/lookup';
import { ToastService } from '../shared/toast/toast.service';

@Component({
  selector: 'nav-bar',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavComponent implements OnInit, OnDestroy {
  @Output()
  openSearch = new EventEmitter();
  isCollapsed = true;
  user: User;
  navItems: NavItemVM[];
  isInError = false;
  expandedNavId = '';
  activeFullRoute = '';
  activeRouteSub: Subscription;
  timeDisabledTooltip = 'This module is available from 8:00 AM - 8:00 PM EST';

  constructor(
    private router: Router,
    private location: Location,
    private authSer: AuthService,
    private aclSer: AclService,
    private toaster: ToastService,
    private searchService: SearchService
  ) {
    this.user = this.authSer.currentUser;
  }

  ngOnInit() {
    // if super Admin then return all modules
    if (this.user.isSuperAdmin) {
      this.aclSer.getListByNames(Object.keys(Lookup.Modules)).subscribe(
        acls => {
          this.navItems = acls.map(this.mapAclToNav).sort((a, b) => a.sortOrder - b.sortOrder);
        },
        error => this.handleErrorGettingModules(error)
      );
    } else {
      // otherwise check user for access
      this.aclSer.getModulesAuthorizedForUser(this.user).subscribe(
        acls => {
          // filter out only ACLs this specific user has read or write access to then map data into nav VM
          this.navItems = acls
            .filter(this.filterSuperAdminModules)
            .map(this.mapAclToNav)
            .filter(x => !x.canHaveNavigableSubItems || x.subItems.length)
            .sort((a, b) => a.sortOrder - b.sortOrder);
        },
        error => this.handleErrorGettingModules(error)
      );
    }

    this.activeFullRoute = this.location.path();

    this.activeRouteSub = this.router.events
      .pipe(
        // Filter to only the navigation end events.
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe((data: NavigationEnd) => {
        this.activeFullRoute = data.urlAfterRedirects;
      });
  }

  private handleErrorGettingModules(error) {
    this.isInError = true;

    if (error._body === 'x-ml-date header is not within a 10 minute window of server time') {
      this.toaster.showError(
        'Error',
        `Your computer's time is more than 10 minutes different from our time. ` +
          `Please fix your computer's time and refresh this page.`
      );
    } else {
      this.toaster.showError(
        'Sorry',
        'An error occurred while trying to populate the navigation bar'
      );
    }
  }

  ngOnDestroy() {
    if (this.activeRouteSub) this.activeRouteSub.unsubscribe();
  }

  logout() {
    this.authSer.logout();
  }

  toggleSize() {
    this.isCollapsed = !this.isCollapsed;

    if (this.isCollapsed) this.expandedNavId = '';
  }

  doOpenSearch() {
    this.searchService.setComponentState({
      isOpen: false,
      targetedComponent: SearchComponentTypes.Floorplans
    });
    this.searchService.setComponentState({
      isOpen: true,
      targetedComponent: SearchComponentTypes.Global
    });
  }

  navAction(item: NavItemVM) {
    this.isCollapsed = false;

    // if has sub items then toggle expand state
    if (item.subItems.length > 0) {
      if (this.expandedNavId === item.id) this.expandedNavId = '';
      else this.expandedNavId = item.id;
    } else {
      // no sub items then just navigate
      this.expandedNavId = '';
      this.router.navigateByUrl(item.route);
    }
  }

  expandBar() {
    this.isCollapsed = false;
    this.expandedNavId = '';
  }

  filterSuperAdminModules(acl: ACL): boolean {
    const mod = Lookup.Modules[acl.ResourceName];
    return mod && !mod.ForSuperAdminsOnly;
  }

  // function declaration so that this.user is accessible
  private mapAclToNav = (acl: ACL): NavItemVM => {
    const mod = Lookup.Modules[acl.ResourceName];
    const navItem: NavItemVM = new NavItemVM(mod, acl);

    const navigableOps = mod.NavigableOperations;
    navItem.canHaveNavigableSubItems = !!navigableOps.length;
    if (navigableOps.length) {
      navItem.subItems = navigableOps
        .filter(op => op.isUserAllowed(this.user, acl))
        .map((op, i) => {
          const subItem = new NavItemVM();
          subItem.title = op.PublicName;
          subItem.iconName = op.Icon;
          subItem.route = op.BaseUrl;
          subItem.sortOrder = i;
          subItem.altRoute = op.AltIdentifyingRoute;
          subItem.needsTooltip = subItem.title.length > 9;
          subItem.disabled = subItem.isDisabled();

          return subItem;
        })
        .sort((a, b) => a.sortOrder - b.sortOrder);
    }
    return navItem;
  };
}

class NavItemVM {
  title: string;
  id: string;
  iconName: string;
  route: string;
  altRoute: string;
  sortOrder: number;
  subItems: NavItemVM[] = [];
  needsTooltip = false;
  canHaveNavigableSubItems = false;
  disabled = false;

  constructor(mod: ApolloModuleModel = null, acl: ACL = null) {
    if (mod && acl) {
      this.title = acl.PublicName;
      this.id = `${acl.ResourceName}Nav`;
      this.iconName = mod.Icon;
      this.route = mod.BaseUrl;
      this.sortOrder = mod.SortOrder;
      this.needsTooltip = this.title.length > 8;
    }
  }

  isDisabled() {
    // Using UTC - 4 hours so it is always ETC no matter users location
    const currTime = new Date().getUTCHours() - 4;

    if (
      (this.title === 'Reporting' || this.title === 'Analytics') &&
      (currTime < 8 || currTime > 20)
    ) {
      this.needsTooltip = true;
      return true;
    }
    return false;
  }

  isRouteMatch(fullUrl: string) {
    return (
      fullUrl === this.route ||
      fullUrl.startsWith(this.route) ||
      (this.altRoute && (fullUrl === this.altRoute || fullUrl.startsWith(this.altRoute)))
    );
  }
}
