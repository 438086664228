import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AuthService } from '../auth/auth.service';
import { User } from '../entities/user';

@Component({
  selector: 'forgot-password',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit {
  successfullyChanged: boolean;
  userName: string;
  token: string;
  newPassword: string;
  confirmPassword: string;
  errorMessage: string;
  user: User;
  requestingAction: boolean;
  isMlEmployee: boolean;

  constructor(private route: ActivatedRoute, private auth: AuthService) {
    this.successfullyChanged = false;
    this.requestingAction = false;
    this.isMlEmployee = false;
  }

  ngOnInit() {
    this.userName = this.route.snapshot.params['userName'];
    this.token = this.route.snapshot.params['token'];

    if (
      this.userName &&
      (this.userName.indexOf('@medialabinc.com') > -1 ||
        this.userName.indexOf('@ml3ds.com') > -1 ||
        this.userName.indexOf('@medialab3dstudio.com') > -1)
    )
      this.isMlEmployee = true;
  }

  changePassword() {
    if (!this.newPassword) {
      this.errorMessage = 'You must enter a valid password.';
      return false;
    } else if (this.newPassword !== this.confirmPassword) {
      this.errorMessage = 'The entered passwords do not match.';
      return false;
    }

    this.requestingAction = true;

    this.auth.updatePasswordFromEmailToken(this.userName, this.token, this.newPassword).subscribe(
      (user: User) => {
        this.requestingAction = false;
        this.successfullyChanged = true;
        this.user = new User(user);
      },
      error => {
        this.errorMessage = 'Error occurred. Please try again or contact support.';
        this.requestingAction = false;
      }
    );
  }
}
