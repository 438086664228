﻿@import '../../assets/scss/_master.scss';

.nav-bar {
  @include absolute(top 0 bottom 0 left 0);
  width: $navBarExpandedWidth;
  background-color: $color1;
  color: $color3;
  font-size: $content2;
  @include transition(width 200ms ease-out);
  @include user-select(none);
  display: flex;
  flex-direction: column;
  align-items: center;

  &.collapsed {
    width: $navBarCollapsedWidth;

    .item {
      padding-left: calc(($navBarCollapsedWidth - 20px) / 2);
    }

    .label {
      opacity: 0;
    }
  }

  .loading-bars {
    @include transform(scale(0.4));

    div {
      background-color: $color4;
    }
  }

  .logo-container {
    width: 100%;
    height: 50px;
    display: inline-flex;
    flex-direction: column;
    flex-grow: 0;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border-bottom: 1px solid $color2;

    .icon {
      width: 25px;
      height: 25px;
    }
  }

  .btns-container {
    width: 100%;
  }

  .item {
    flex-grow: 0;
    width: 100%;
    text-align: left;
    padding: 15px 0 15px 25px;
    @include box-sizing(border-box);
    white-space: nowrap;
    position: relative;
    cursor: pointer;
    @include animation(fadeIn 250ms linear);
    outline: none;
    @include transition(all 150ms ease-out);
    font-weight: 500;
    text-transform: uppercase;

    &.active {
      color: $color4;

      &:before {
        content: '';
        display: block;
        @include absolute(top 0 bottom 0 left 0);
        width: 5px;
        background-color: $color4;
      }

      .icon svg {
        path,
        polygon {
          fill: $color4;
        }
      }
    }

    &:hover {
      background-color: $color2;
      color: $color4;

      .icon svg {
        path,
        polygon {
          fill: $color4;
        }
      }
    }

    &:active {
      background-color: rgba($color2, 0.5);
    }

    &.border-bottom {
      border-bottom: 1px solid $color2;
    }

    .icon,
    .label {
      display: inline-block;
      vertical-align: middle;
    }

    .icon {
      height: 20px;
      width: 20px;
      margin-right: 10px;

      svg {
        path,
        polygon {
          @include transition(fill 150ms ease-out);
          fill: $color3;
        }
      }
    }

    .label {
      max-width: $navBarExpandedWidth * 0.5;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .modules-container {
    width: 100%;
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    border-bottom: 1px solid $color2;

    &::-webkit-scrollbar-track {
      @include box-shadow(inset 0 0 6px rgba(0, 0, 0, 0.3));
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color3;
      border-radius: 10px;
    }

    button {
      color: inherit;

      &:disabled {
        opacity: 0.5;
      }
    }
  }

  .module {
    &.expanded {
      > .item {
        padding-bottom: 7px;
      }

      .sub-items {
        max-height: 210px;
        margin-top: 2px;
        border-bottom: 1px dotted $color2;
      }
    }

    &:last-child {
      .sub-items {
        border: none;
      }
    }

    .sub-items {
      width: 100%;
      @include box-sizing(border-box);
      padding-left: 45px;
      max-height: 0;
      @include transition(max-height 200ms ease-out);
      overflow: hidden;

      .item {
        font-weight: normal;
        text-transform: none;
        padding: 8px 0 8px 10px;

        &:last-child {
          margin-bottom: 8px;
        }

        &.active:before {
          width: 0;
        }
      }
    }
  }

  .fixed {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    .btn {
      height: 30px;
      width: 30px;
      cursor: pointer;
      margin-bottom: 15px;

      &.search {
        svg path {
          fill: $color3;
        }
      }
    }
  }

  .carrot {
    @include transition(transform 200ms ease-out);
    &.left {
      @include transform(scaleX(-1));
    }
  }
}
