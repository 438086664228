import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AuthService } from '../../auth/auth.service';
import { User, UserService } from '../../entities';
import { ToastService } from '../../shared/toast/toast.service';

@Component({
  selector: 'setup-password',
  templateUrl: './setup-password.component.html',
  styleUrls: ['./setup-password.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SetupPasswordComponent implements OnInit {
  successfullySet: boolean;
  userName: string;
  token: string;
  newPassword: string;
  confirmPassword: string;
  errorMessage: string;
  user: User;
  requestingAction: boolean;
  isMlEmployee: boolean;
  needsInvite: boolean;
  inviteSuccessfullySent: boolean;

  constructor(
    private route: ActivatedRoute,
    private auth: AuthService,
    private toaster: ToastService,
    private userService: UserService
  ) {
    this.errorMessage = '';
    this.successfullySet = false;
    this.requestingAction = false;
    this.isMlEmployee = false;
    this.needsInvite = false;
    this.inviteSuccessfullySent = false;
  }

  ngOnInit() {
    this.userName = this.route.snapshot.params['userName'];
    const tokenParam = this.route.snapshot.params['token'];

    if (this.userName && tokenParam) {
      this.requestingAction = true;
      this.auth.isValidToken(this.userName, tokenParam).subscribe(
        res => {
          this.errorMessage = '';
          this.token = tokenParam;
          this.requestingAction = false;
        },
        error => {
          if (error.status === 404) {
            this.errorMessage = `We didn't recognize the user account. \n Please try again or contact our <a href="https://medialab3dsolutions.atlassian.net/servicedesk/customer/portal/2" target="_blank">support</a> team.`;
          } else if (error.status === 408) {
            this.errorMessage = `Your link has expired. Please request a new one by clicking the button below. If you continue to have issues, please contact our <a href="https://medialab3dsolutions.atlassian.net/servicedesk/customer/portal/2" target="_blank">support</a> team.`;
          } else {
            this.errorMessage = `An error occurred while preparing to setup your password.\n Please try again or contact our <a href="https://medialab3dsolutions.atlassian.net/servicedesk/customer/portal/2" target="_blank">support</a> team.`;
          }
          this.requestingAction = false;
          this.needsInvite = true;
        }
      );
    } else {
      this.errorMessage = `An error occurred while preparing to setup your password.\n Please try again or contact our <a href="https://medialab3dsolutions.atlassian.net/servicedesk/customer/portal/2" target="_blank">support</a> team.`;
    }

    if (
      this.userName &&
      (this.userName.indexOf('@medialabinc.com') > -1 ||
        this.userName.indexOf('@ml3ds.com') > -1 ||
        this.userName.indexOf('@medialab3dstudio.com') > -1)
    )
      this.isMlEmployee = true;
  }

  setupPassword() {
    if (!this.newPassword) {
      this.errorMessage = `You must enter a valid password. Please try again following the criteria above. If this problem persists then contact our <a href="https://medialab3dsolutions.atlassian.net/servicedesk/customer/portal/2" target="_blank">support</a> team.`;
      return false;
    } else if (this.newPassword !== this.confirmPassword) {
      this.errorMessage = 'These passwords do not match. Please try again.';
      return false;
    }

    this.requestingAction = true;

    this.auth.updatePasswordFromEmailToken(this.userName, this.token, this.newPassword).subscribe(
      (user: User) => {
        this.requestingAction = false;
        this.successfullySet = true;
        this.user = new User(user);
      },
      error => {
        this.errorMessage = `You must enter a valid password. Please try again following the criteria above. If this problem persists then contact our <a href="https://medialab3dsolutions.atlassian.net/servicedesk/customer/portal/2" target="_blank">support</a> team.`;
        this.requestingAction = false;
      }
    );
  }

  resendInvite() {
    this.toaster.setState(true, { onlyLoadingIndicator: true });
    this.needsInvite = false;
    this.inviteSuccessfullySent = true;
    this.userService.resendInvite(this.userName).subscribe(
      () => {
        this.toaster.setState(true, {
          title: 'Invite sent',
          useSuccessStyle: true
        });
      },
      error => {
        let message = '';
        if (error.status === 404)
          message =
            'User ' +
            this.user.UserName +
            ` not found. Please try again or contact our <a href="https://medialab3dsolutions.atlassian.net/servicedesk/customer/portal/2" target="_blank">support</a> team.`;
        else
          message = `An error occurred. Please try again or contact our <a href="https://medialab3dsolutions.atlassian.net/servicedesk/customer/portal/2" target="_blank">support</a> team.`;

        this.toaster.setState(true, {
          title: 'Whoops!',
          message,
          useErrorStyle: true
        });
      }
    );
  }
}
