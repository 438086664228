import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastService } from '../../shared/toast/toast.service';
import { HttpClient, HttpResponse } from '@angular/common/http';

@Component({
  selector: 'public',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PublicComponent implements OnInit {
  pageName: string;
  pageId: string;
  html: string;
  pages: object = {
    tos: '1610121223',
    privacy: '1610186777',
    support: '1606942870',
    docs: '1640431649'
  };

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private toaster: ToastService
  ) {}

  ngOnInit(): void {
    this.toaster.showLoading();

    this.pageName = this.route.snapshot.queryParams['page'];
    this.pageId = this.pages[this.pageName];

    this.getPage(this.pageId).subscribe((res: HttpResponse<string>) => {
      if (res && res.status === 200) {
        let tempHtml: string;
        tempHtml = res.body;

        tempHtml = tempHtml.replace(/\\"/g, `'`);
        tempHtml = tempHtml.replace(/\\n/g, '').replace('View Source', '');
        const rx = new RegExp('<script[\\d\\D]*?/script>', 'g');
        tempHtml = tempHtml.replace(rx, '');

        tempHtml = tempHtml.replace(/<a class/g, '<a target="_blank" class');
        tempHtml = tempHtml.replace(/<a href/g, '<a target="_blank" href');

        tempHtml = tempHtml.slice(1, -1);
        this.html = tempHtml;

        this.toaster.hideLoading();
      }
    });
  }

  getPage(id: string) {
    return this.http.get(`/api/utility/confluencepage/${id}`, { observe: 'response' });
  }
}
