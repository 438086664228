﻿@import '../../assets/scss/_master.scss';

.main-section home {
  // temp until whole app is updated to use flexboxgrid classes
  .row {
    width: auto !important;
  }

  .field {
    margin: 0 !important;
  }

  .top .title-area {
    display: inline-flex;
  }

  .bottom {
    .loading-bars {
      @include align-horizontally();
    }

    .static-message {
      width: 50%;
    }

    .form-box {
      &#quick-links {
        .flex-column:first-child {
          margin-right: 1rem;
        }

        .btn {
          width: 100%;
          margin-bottom: 10px;
          justify-content: center;
        }
        #dynamic-quick-links {
          max-height: 150px;
          width: 82%;
          flex-wrap: wrap;
          align-content: flex-start;
          column-gap: 1rem;

          .quick-link {
            width: 210px;
            > :first-child {
              margin-right: 1rem;
            }
          }
        }
      }

      &#contact-info {
        .field {
          margin-bottom: 15px;

          .label {
            margin-bottom: 4px;
          }
        }
      }

      &#submit-help {
        .btn {
          margin: 8px auto 0;
        }
      }

      &#marketing-feed {
        .box-section {
          display: flex;
          flex-direction: column;

          .btn {
            align-self: flex-end;
          }

          p {
            margin-top: 0;

            p {
              margin: 0;
              text-align: center;
            }

            img {
              width: 100%;
              height: auto;
              object-fit: contain;
              object-position: center center;
            }

            strong {
              display: block;
            }
          }
        }
      }

      &#feedback {
        iframe {
          width: 100%;
          height: 725px;
          border: none;

          @media only screen and (min-width: 75em) {
            height: 825px;
          }
        }

        p {
          font-size: 14px;
          margin-top: 0;
          align-self: stretch;
        }
      }

      .row {
        .field {
          .image {
            width: 100px;
            height: 100px;
            border-radius: 6px;
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat;
            margin: 8px 0;
          }
        }
      }
    }
  }

  #job-status-report {
    display: none;

    &.show {
      display: block;
    }

    .pbi-refresh-container {
      padding: 1px 0 5px 16px;
      .tooltip {
        padding-left: 8px;
      }
    }

    .job-status-report-container {
      position: relative;
      height: 750px;
    }
  }

  #report-unavailable-message {
    padding: 20px;

    h3 {
      font-size: 1rem;
      font-style: italic;
    }
  }

  .btn.link-to-new-order {
    background-color: $color4;

    &:hover {
      background-color: $darkColor4;
    }
    .icon {
      svg path {
        fill: $color2;
      }
    }
    .label {
      color: $color2;
    }
  }
}
