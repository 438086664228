public {
  > div {
    overflow-x: auto;
    height: 100%;
    padding: 1rem;
  }
}

.initial-loader {
  display: none;
}
