<div class="setup-password">
    <div class="top-bar">
        <div class="logo"></div>
        <div class="login btn" routerLink="/login">Login</div>
    </div>
    <div class="content">
        <div class="form-container" *ngIf="userName && token">
            <div class="needs-setup" *ngIf="!successfullySet">
                <div class="title">Password Setup</div>
                <div class="sub-title">Enter your new password and confirm it below.</div>
                <div class="sub-title help">Password must be at least 8 characters and contain at least 1 uppercase, 1 lowercase, and 1 special character or number.</div>
                <form id="setupForm" (ngSubmit)="setupPassword()">
                    <input name="newPassword" type="password" placeholder="New Password" [(ngModel)]="newPassword" #newPw="ngModel" />
                    <input name="confirmPassword" type="password" placeholder="Confirm Password" [(ngModel)]="confirmPassword" #confirmPw="ngModel" [ngClass]="{invalid: newPw.value !== confirmPw.value}" />
                    <div class="sub-title help error" [innerHtml]="errorMessage"></div>
                    <button class="btn submit" [ngClass]="{busy: requestingAction}" type="submit">Submit</button>
                </form>
            </div>
            <div class="successfully-setup" *ngIf="successfullySet">
                <div class="title">Success!</div>
                <div class="sub-title">Click the button below to return to the login page and use this new password.</div>
                <div class="btn login" routerLink="/login">Login</div>
            </div>
        </div>
        <div class="form-container" *ngIf="!userName || !token">
            <div *ngIf="needsInvite">
                <div class="title">Whoops!</div>
                <div class="sub-title" [innerHtml]="errorMessage">
                </div>
                <div id="needsInvite" class="btn invite" (click)="resendInvite()">
                    <div class="icon">
                        <svg id="change-password" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <title>reset-password</title>
                            <path d="M19.62,20.53H2.82A3,3,0,0,1,0,17.44V3.11A3,3,0,0,1,2.82,0H16.53a3,3,0,0,1,2.82,3.09V7.42a.5.5,0,1,1-1,0V3.11A2,2,0,0,0,16.53,1H2.82A2,2,0,0,0,1,3.11V17.44a2,2,0,0,0,1.82,2.09h16.8a.51.51,0,0,1,.5.5A.5.5,0,0,1,19.62,20.53Z" transform="translate(0 -0.02)" fill="#fff" />
                            <polygon points="19.87 24 19.19 23.27 22.53 20.15 19.19 17.02 19.87 16.29 24 20.15 19.87 24" fill="#fff" />
                            <path d="M13.84,7.53V7.46c0-2.57-1.64-4.67-3.64-4.67H9.3c-2,0-3.64,2.1-3.64,4.67v.07a2,2,0,0,0-1.85,2v5.81a2,2,0,0,0,2,2h8a2,2,0,0,0,2-2V9.48A2,2,0,0,0,13.84,7.53ZM9.3,3.8h.9c1.45,0,2.64,1.64,2.64,3.66v0H6.66v0C6.66,5.44,7.84,3.8,9.3,3.8Zm5.39,11.49a1,1,0,0,1-1,1h-8a1,1,0,0,1-1-1V9.48a1,1,0,0,1,1-1h8a1,1,0,0,1,1,1Z" transform="translate(0 -0.02)" fill="#fff" />
                            <path d="M9.75,9.43a1.69,1.69,0,0,0-1.69,1.68,1.67,1.67,0,0,0,1.19,1.6v2.22a.5.5,0,0,0,.5.5.5.5,0,0,0,.5-.5V12.71a1.68,1.68,0,0,0-.5-3.28Z" transform="translate(0 -0.02)" fill="#fff" />
                        </svg>
                    </div>
                    <span class="label">Resend Invite</span>
                </div>
            </div>
            <div class="sent-invite" *ngIf="inviteSuccessfullySent">
                <div class="title">Invite sent</div>
                <div class="sub-title">Please check your email and follow the instructions to set up your password. </div>
            </div>
        </div>
    </div>
</div>