<button *ngIf="jobStatusTrackerFeatureEnabled || notifications.length" class="notification-icon" (click)="toggleNotificationPopover()">
    <svg-helper svgName="bell"></svg-helper>
    <span *ngIf="unreadNotificationCount" class="notification-badge">{{unreadNotificationCount}}</span>
</button>
<div *ngIf="showNotificationPopover" class="notification-popover">
    <div class="notification-title">Notifications</div>
    <mat-spinner *ngIf="loading" diameter="50"></mat-spinner>
    <div *ngIf="!loading" class="notification-popover-content">
        <div *ngIf="!jobsThatNeedAttention.length && !notifications.length" class="no-notifications">All Clear!</div>
        <div *ngFor="let notification of notifications" class="notification-item" [innerHtml]="notification.Content | safeHtml" (click)="handleNotificationClick(notification.Id)"></div>
        <div *ngFor="let job of jobsThatNeedAttention" class="notification-item">{{job.JobId}} - {{job.JobName}}: Needs Attention<br /><a [routerLink]="['/orders/history/jobs/details', { jobId: job.JobId}]">Click here</a> to view the job</div>
    </div>
</div>