﻿<div class="v2">
  <div class="top">
    <div class="title-area">
      <div class="text">
        <div class="icon">
          <svg-helper svgName="circle_user"></svg-helper>
        </div>
        <div class="title">Welcome, {{ user?.FullName }}!</div>
        <div class="sub-title">
          <div class="item">{{ user?.ClientName }}</div>
        </div>
      </div>
      <notification-bell (hasJobsThatNeedAttention)="hasJobsThatNeedAttention = true"></notification-bell>
    </div>
  </div>
  <div class="bottom">
    <div *ngIf="initInProgress" class="loading-bars">
      <div></div>
      <div></div>
      <div></div>
    </div>
    <div *ngIf="!initInProgress && !showSplashModules" class="fadeIn static-message">
      <div class="title">Welcome to Converge</div>
      <div class="para">
        Converge brings together powerful metrics and tools to help you engage buyers,
        identify sales trends, and edit your content all in one place.
      </div>
    </div>
    <div *ngIf="!initInProgress && showSplashModules" class="fadeIn">
      <div class="row">
        <div id="quick-links" class="form-box col-xs-8 col-lg-6">
          <div class="box-header transparent">Quick Links</div>
          <div *ngIf="quickLinks.length" class="flex-column" id="dynamic-quick-links">
            <div *ngFor="let quicklink of quickLinks" class="quick-link">
              <button *ngIf="quicklink.urlType === 'internal'" class="btn standard-positive" [routerLink]="quicklink.route.Url">
                <div *ngIf="quicklink.svgContent" class="icon" [innerHTML]="quicklink.svgContent | safeHtml"></div>
                <div class="label">{{quicklink.label}}</div>
              </button>
              <div *ngIf="quicklink.urlType === 'external'" class="btn standard-positive external-link">
                <div *ngIf="quicklink.svgContent" class="icon" [innerHTML]="quicklink.svgContent | safeHtml"></div>
                <div class="label">{{quicklink.label}}</div>
                <a [href]="quicklink.url" target="_blank"></a>
              </div>
            </div>
          </div>
          <div *ngIf="quickLinks.length === 0" class="flex-row">
            <div class="flex-column align-left">
              <button *ngIf="hasLinkGeneratorAccess" class="btn standard-positive" [routerLink]="'/tools/link-generator'">
                <div class="icon">
                  <svg-helper svgName="link_generator"></svg-helper>
                </div>
                <div class="label">Link Generator</div>
              </button>
              <div class="btn standard-positive external-link">
                <div class="icon">
                  <svg-helper svgName="medialab_logo"></svg-helper>
                </div>
                <div class="label">MediaLab Homepage</div>
                <a href="https://www.medialab3dsolutions.com/" target="_blank"></a>
              </div>
              <div class="btn standard-positive external-link">
                <div class="icon">
                  <svg-helper svgName="medialab_logo"></svg-helper>
                </div>
                <div class="label">About Our Products</div>
                <a href="https://www.medialab3dsolutions.com/portfolio/" target="_blank"></a>
              </div>
            </div>
            <div class="flex-column">
              <button *ngIf="hasOrdersAccess" class="btn standard-positive" [class.alert]="hasJobsThatNeedAttention" [routerLink]="'/orders/history/jobs/in-production'">
                <div class="icon">
                  <svg-helper svgName="history_clock"></svg-helper>
                </div>
                <div class="label">In-Production Jobs</div>
              </button>
              <div class="btn standard-positive external-link">
                <div class="icon">
                  <svg-helper svgName="ftp"></svg-helper>
                </div>
                <div class="label">Access MediaLab FTP</div>
                <a href="https://ftp.medialabinc.com/WebInterface/login.html?u={{user?.FTPUsername}}&p={{user?.FTPPassCipher}}" target="_blank"></a>
              </div>
              <button *ngIf="showKioskLink" class="btn standard-positive" [routerLink]="'/tools/offline-scp'">
                <div class="icon">
                  <svg-helper svgName="medialab_logo"></svg-helper>
                </div>
                <div class="label">Visit Kiosk</div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-6 col-lg-4">
          <div id="submit-help" class="form-box bordered">
            <div class="box-header">Contact Support</div>
            <div class="box-content light no-padding">
              <iframe src="https://form.smartsuite.com/srillxfa/oPtnpW0kpC?header=false" width="100%" height="600px" style="border: none;"></iframe>
            </div>
          </div>
        </div>
        <div class="col-xs-6 col-lg-8">
          <div id="marketing-feed" class="form-box bordered">
            <div class="box-header">Latest News</div>
            <div class="box-content light">
              <div *ngIf="marketingRssItem" class="box-section">
                <div class="section-header">{{ marketingRssItem.title }}</div>
                <p [innerHtml]="marketingRssItem.summary"></p>
                <div class="btn standard-positive external-link">
                  <div class="label">More</div>
                  <a [href]="marketingRssItem.link" target="_blank"></a>
                </div>
              </div>
              <div *ngIf="!marketingRssItem" class="box-section">
                <div class="section-header">
                  Sorry, unable to retrieve blog posts at this time
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>